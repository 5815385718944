// Core packages
import { createStore } from "redux";

// Third party packages
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Custom packages
import rootReducer from "./reducers";

/**
 * Script start
 */

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["luoghi"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
