// Core packages
import React from "react";

// Third party packages
import { Row, Col } from "react-bootstrap";

// Custom packages
import azero from "../assets/icone/azero.png";

/**
 * Script start
 */
class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <Row>
          <Col xs={6}>
            <p className="copyright">
              <img alt="Azero S.r.l." src={azero}></img> &nbsp;©2022 Azero
            </p>
          </Col>
          <Col xs={6}>
            <p className="text-right">
              <a
                href="https://www.azero.it/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Note legali
              </a>{" "}
              |{" "}
              <a
                href="https://www.azero.it/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Footer;
