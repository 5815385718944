// Core packages
import React, { Component } from "react";

// Third party packages
import { connect } from "react-redux";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// Custom packages
import back from "../assets/icone/back-arrow.png";
import Filtro from "../components/Filtro";
import Pin from "../components/Pin";
import Direzione from "../components/Direction";

/**
 * Script start
 */
const containerStyle = {
  width: "100%",
  height: "100%",
};

class Mappa extends Component {
  /**
   * TASK
   *
   * 1. Mappa inaccessibile se no-geolocation
   *    ideale: se user non da consenso viene fatto redirect a una pagina di errore
   * 2. Far vedere i POI
   * 3. Dati nell'anteprima
   * 4. In home, rendere cliccabilit tutti i bottoncini non solo freccia
   * 5. Se si clicca un'anteprima dalla mappa si deve aprire la scheda
   * 6. Consentire di accedere alla scheda di un POI da URL
   * 7. Evitare la possibilità di scrollare orizzontalmente l'anteprima
   *
   */

  center = {};
  // center = {
  //   lat: 45.464211,
  //   lng: 9.191383,
  // };
  state = {
    info: "-1",
    currClick: {},
    canRender: false,
    showError: false,
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showPosition(position);
          this.setState({ canRender: true });
        },
        (position) => {
          this.setState({ showError: true });
        }
      );
    }
  };

  showPosition = (position) => {
    console.log("position", position);
    this.center.lat = position.coords.latitude;
    this.center.lng = position.coords.longitude;
  };

  componentWillMount() {
    this.getLocation();
  }

  setCurrClick(coord) {
    this.setState({ currClick: coord });
  }

  backHome = () => {
    window.location.href = "/homepage/";
  };

  componentDidUpdate() {
    const watchID = navigator.geolocation.watchPosition((position) => {
      console.log("position.coords.latitude: ", position.coords.latitude);
      this.showPosition(position);
      this.setState({ canRender: true });
      // doSomething(position.coords.latitude, position.coords.longitude);
    });
    console.log("watchID: ", watchID);
  }

  render() {
    return (
      <div className="map-div">
        <p
          className="text-left close-map"
          style={{ position: "absolute", zIndex: "1000" }}
        >
          <img
            alt="Indietro"
            src={back}
            style={{ width: "36px", cursor: "pointer", margin: "15px" }}
            onClick={() => this.backHome()}
          ></img>
        </p>

        <LoadScript googleMapsApiKey="AIzaSyCK6QxdS3MoVolfonrt6ev_EGjGnCkes2E">
          {this.state.canRender && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={this.center}
              zoom={15}
              options={{
                mapId: "b227bbdf25570d97",
                mapTypeControl: false,
              }}
            >
              <Pin />

              <Marker
                position={this.center}
                icon={"/assets/img/position@2x.png"}
                title="Posizione corrente"
              />

              {/* <Direzione /> */}
            </GoogleMap>
          )}
        </LoadScript>

        {!this.state.canRender && this.state.showError && (
          <div
            style="position: absolute;top: calc(50% - 200px);height: 200px;display: flex;align-items: center;flex-wrap: wrap;">
            <h3>Errore durante il caricamento della mappa</h3>
            <h4>
              Per caricare la mappa devi abilitare i servizi di localizzazione
            </h4>
          </div>
        )}

        <Filtro />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Mappa);
