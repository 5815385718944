import React from "react";
import cover from "../assets/cover-vigevano.png";
import question from "../assets/icone/question.png";
import map from "../assets/icone/map.png";
import ar from "../assets/icone/ar.png";
import frecciawhite from "../assets/icone/white.png";
import frecciablack from "../assets/icone/black.png";
import { Container, Row, Col } from "react-bootstrap";
import milano from "../assets/icone/vigevano.png";
import infologo from "../assets/icone/icon-info-outline.png";
import Info from "../pages/Info";
import Footer from "../components/Footer";
class Homepage extends React.Component {
  state = {
    openinfo: false,
  };
  closeInfo = () => {
    this.setState({ openinfo: false });
  };
  render() {
    return this.state.openinfo ? (
      <Container className="homepage">
        <Row>
          {" "}
          <Info close={() => this.closeInfo()}></Info>{" "}
        </Row>
      </Container>
    ) : (
      <Container className="homepage">
        <Row>
          <Col md={12} className="top-bar">
            <img
              src={milano}
              alt="Vigevano"
              style={{ width: "122px", float: "left" }}
            ></img>
            <img
              src={infologo}
              alt="Info"
              style={{ width: "20px", float: "right", cursor: "pointer" }}
              onClick={() => this.setState({ openinfo: true })}
            ></img>
          </Col>
        </Row>
        <Row>
          <img src={cover} alt="Cover" style={{ width: "100%" }}></img>
        </Row>
        <Row>
          <Col md={12}>
            <p className="text-bold title">
              VIVI VIGEVANO DALLA TUA PROSPETTIVA
            </p>
            <p className="text-regular descr">
              Vai alla scoperta della città di Vigevano attraverso l’esperienza
              straordinaria della Realtà Aumentata.
            </p>
          </Col>
        </Row>
        <Row className="menu-btn-red">
          <Col xs={3}>
            <img
              src={ar}
              alt="Realtà aumentata"
              style={{ width: "46px" }}
            ></img>
          </Col>
          <Col xs={6} className="text-left text-bold">
            <p>
              REALT&Agrave; <br /> AUMENTATA
            </p>
          </Col>
          <Col xs={3}>
            <a href="/it/ar">
              {" "}
              <img className="freccia" alt="Freccia" src={frecciawhite}></img>
            </a>
          </Col>
        </Row>
        <Row className="menu-btn-normal">
          <Col xs={3}>
            <img alt="Mappa" src={map} style={{ width: "42px" }}></img>
          </Col>
          <Col xs={6} className="text-left text-bold">
            <p>MAPPA</p>
          </Col>
          <Col xs={3}>
            <a href="/it/mappa">
              <img className="freccia" alt="Freccia" src={frecciablack}></img>
            </a>
          </Col>
        </Row>
        <Row className="menu-btn-normal">
          <Col xs={3}>
            <img
              alt="Come funziona"
              src={question}
              style={{ width: "38px" }}
            ></img>
          </Col>
          <Col xs={6} className="text-left text-bold">
            <p>
              COME <br /> FUNZIONA?
            </p>
          </Col>
          <Col xs={3}>
            <a href="/it/come-funziona">
              <img className="freccia" alt="Freccia" src={frecciablack}></img>
            </a>
          </Col>
        </Row>
        <br />
        <Footer></Footer>
      </Container>
    );
  }
}

export default Homepage;
