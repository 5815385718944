// Core packages
import React from "react";

// Third party packages
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Custom packages
import "./App.css";
import Homepage from "./pages/Homepage";
import Mappa from "./pages/Mappa";
import Info from "./pages/Info";

import SliderDot from "./components/SliderDot";
import ArApp from "./components/ArApp";
import Arcont2 from "./components/AR/Content2";

/**
 * Script start
 */
class App extends React.Component {
  state = { width: 0, height: 0 };
  supportedLanguages = ["en", "it"];
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  detect() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  render() {
    {
      if (!this.detect()) {
        // return "Visualizzazione non possibile su Desktop";
      }
    }

    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/:lang/" element={<Homepage />} />
            <Route exact path="/:lang/come-funziona" element={<SliderDot />} />
            <Route exact path="/:lang/mappa" element={<Mappa />} />
            <Route exact path="/:lang/ar-cont" element={<Arcont2 />} />
            <Route exact path="/:lang/ar/:placeId" element={<ArApp />} />
            <Route exact path="/:lang/ar" element={<ArApp />} />
            <Route exact path="/:lang/info" element={<Info />} />
            <Route exact path="/info" element={<Info />} />
            <Route
              path="/:lang/"
              render={(props) =>
                this.supportedLanguages.includes(props.match.params.lang) ? (
                  <Homepage {...props} />
                ) : (
                  <Navigate to={`/it/${props.match.params.lang}`} />
                )
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
