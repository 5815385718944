// Core packages
import React, { useCallback, useEffect, useState } from "react";

// Third party packages
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Custom packages
import Content from "./Content";
import cat from "../../data/categorie.json";
import luoghi from "../../data/luoghi.json";
import PUBLIC_URL from '../../config/index';

/**
 * Script start
 */
const Anteprima = (props) => {
  const { luogoid, close, open, distance, defaultStatus } = props;
  const [status, setStatus] = useState(defaultStatus || "preview");
  const [luogo, setLuogo] = useState({});
  const navigate = useNavigate();

  const swipeUp = () => {
    setStatus("content");
    open();
  };
  const swipeDown = () => {
    setStatus("preview");
    close();
  };
  const handlers = useSwipeable({
    onSwipedUp: () => swipeUp(),
    onSwipedDown: () => swipeDown(),
  });

  const closeLuogoContent = () => {
    setLuogo({});
    close();

    if (defaultStatus === "content") {
      navigate(`/ar`);
    }
  };

  useEffect(() => {
    const currentLuogo = luoghi.find(
      (luogo) => luogo.id?.toString() === luogoid?.toString()
    );
    setLuogo(currentLuogo);
  }, []);

  return (
    <React.Fragment>
      {status === "content" && (
        <React.Fragment>
          <Content luogo={luogo} closeFn={() => closeLuogoContent()}></Content>
        </React.Fragment>
      )}
      {status === "preview" && (
        <React.Fragment>
          <div
            {...handlers}
            style={{
              width: "100%",
              background: "white",
              height: 100,
              position: "fixed",
              bottom: 0,
              right: 0,
              left: 0,
            }}
            key={luogo.id}
          >
            <div id="anteprima">
              <div className="swipe">
                <p className="swipe-icon"></p>
              </div>
              <div id="content-anteprima" onClick={() => swipeUp()}>
                <div className="image-anteprima">
                  <img
                    src={
                      PUBLIC_URL +
                      "/assets/img/luoghi" +
                      luogo.path +
                      "cover/" +
                      luogo.img
                    }
                    alt={luogo.nome}
                    style={{ width: "140px" }}
                  />
                </div>
                <div className="descr-anteprima">
                  <p className="">
                    {distance > 0 &&
                      !Number.isNaN(parseInt(distance.toString())) && (
                        <span className="text-bold distance">{distance} m</span>
                      )}
                  </p>
                  <p className="text-bold size-16">{luogo.nome}</p>
                  <p className="size-14">{luogo.excerpt}</p>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Anteprima);
