import React from "react";
import arrowup from "../assets/icone/up.png";
import { connect } from "react-redux";
import arrowdown from "../assets/icone/down.png";
import check from "../assets/icone/check.png";
import { addFilter } from "../store/actions/index";
import cat from "../data/categorie.json";

class Filtro extends React.Component {
  state = { open: false };

  render() {
    return (
      <div id="filtro" className={this.state.open ? "open-filter" : ""}>
        {this.state.open ? (
          <div>
            {" "}
            <p className="text-regular ">
              Filtra per categoria
              <img
                className="arrow-filtro"
                src={arrowdown}
                onClick={() => this.setState({ ...this.state, open: false })}
              ></img>
            </p>
            {cat.map((element, index) => {
              return (
                <p
                  key={index}
                  className="text-regular filtro-list"
                  style={{ color: element.color }}
                  onClick={() => this.props.addFilter(element.slug)}
                >
                  <span>
                    <img
                      className="icon-filter"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/categorie/" +
                        element.path +
                        "icona/" +
                        element.img
                      }
                    ></img>
                    {element.nome}
                  </span>{" "}
                  {this.props.filtri_attivi.some((p) => {
                    if (p.nome == element.slug && p.stato) {
                      return true;
                    }
                  }) ? (
                    <img className="check" src={check}></img>
                  ) : (
                    ""
                  )}
                </p>
              );
            })}
          </div>
        ) : (
          <p className="text-regular">
            Filtra per categoria
            <img
              className="arrow-filtro"
              src={arrowup}
              onClick={() => this.setState({ ...this.state, open: true })}
            ></img>
          </p>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  addFilter: (sl) => dispatch(addFilter(sl)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Filtro);
