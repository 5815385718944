import React from "react";
import YouTube from "react-youtube";

class Video extends React.Component {
  render() {
    const opts = {
      height: "390",
      width: "100%",
      playerVars: {
        autoplay: 0,
      },
    };
    return (
      <div className="foto-section">
        {this.props.video.map((element, index) => {
          return (
            <YouTube
              key={index}
              videoId={element}
              opts={opts}
              onReady={this._onReady}
            />
          );
        })}
      </div>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default Video;
