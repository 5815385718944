// Core packages
import React, { Component } from "react";

// Third party packages
import { connect } from "react-redux";
import { Marker, InfoWindow } from "@react-google-maps/api";

// Custom packages
import luoghi from "../data/luoghi.json";
import cat from "../data/categorie.json";

/**
 * Script start
 */
class Pin extends Component {
  state = {
    info: "-1",
    filtri: this.props.filtri_attivi,
  };

  componentWillReceiveProps(nextprops) {
    this.setState({ filtri: this.props.filtri_attivi });
  }

  showInfo = (element, index) => {
    //this.props.currClick(element.pos);
    this.setState({ info: index });
  };
  closeInfo = () => {
    this.setState({ info: "-1" });
  };

  render() {
    function getPinImage(categoria) {
      var pinImage = "/assets/img/poi-mappa-nocategory@2x.png";
      if (categoria === "chiese") {
        pinImage = "/assets/img/poi-mappa-chiese@2x.png";
      } else if (categoria === "arte") {
        pinImage = "/assets/img/poi-mappa-arte@2x.png";
      } else if (categoria === "food") {
        pinImage = "/assets/img/poi-mappa-food_beverage@2x.png";
      }
      return pinImage;
    }

    return luoghi.map((element, index) => {
      var iconImg = getPinImage(element.categoria);
      return this.props.filtri_attivi.some((p) => {
        if (p.nome == element.categoria && p.stato) {
          return true;
        }
      }) ? (
        <Marker
          key={index}
          position={element.pos}
          icon={iconImg}
          title={element.nome}
          onClick={() => this.showInfo(element, index)}
        >
          {this.state.info == index ? (
            <InfoWindow onCloseClick={() => this.closeInfo()}>
              <div className="info-mappa">
                {cat.map((el, index) => {
                  if (el.slug === element.categoria) {
                    return (
                      <span key={index} style={{ color: el.color }}>
                        <img
                          alt={el.nome}
                          className="icon-info"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/categorie/" +
                            el.path +
                            "icona/" +
                            el.img
                          }
                        ></img>
                        <span className="info-mappa-cat">{el.nome}</span>
                      </span>
                    );
                  }
                })}
                <p>{element.nome}</p>
              </div>
            </InfoWindow>
          ) : (
            ""
          )}
        </Marker>
      ) : (
        ""
      );
    });
  }
}
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Pin);
