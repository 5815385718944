// Core packages
import React, { Component } from "react";

// Third party packages
import { DirectionsService, DirectionsRenderer } from "@react-google-maps/api";

// Custom packages

/**
 * Script start
 */
class Directions extends Component {
  testvar = {
    response: null,
    travelMode: "WALKING",
    origin: {},
    destination: { lat: 45.5801333, lng: 9.297613 },
  };

  constructor(props) {
    super(props);

    this.getLocation();
    this.directionsCallback = this.directionsCallback.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    }
  };

  showPosition = (position) => {
    this.testvar.origin = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
  };

  directionsCallback(response) {
    if (response !== null) {
      if (response.status === "OK") {
        this.testvar.response = response;
      }
    }
  }

  onClick() {
    if (this.origin.value !== "" && this.destination.value !== "") {
      this.setState(() => ({
        origin: this.origin.value,
        destination: this.props.dest,
      }));
    }
  }

  onMapClick(...args) {
    console.log("onClick args: ", args);
  }

  render() {
    return (
      <div>
        {this.testvar.destination !== "" && this.testvar.origin !== "" && (
          <DirectionsService
            // required
            options={{
              destination: this.testvar.destination,
              origin: this.testvar.origin,
              travelMode: this.testvar.travelMode,
            }}
            // required
            callback={this.directionsCallback}
          />
        )}

        {this.testvar.response !== null && (
          <DirectionsRenderer
            // required
            options={{
              directions: this.testvar.response,
            }}
          />
        )}
      </div>
    );
  }
}

export default Directions;
