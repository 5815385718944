// Core packages
import React from "react";

// Third party packages
import { Row, Col } from "react-bootstrap";

// Custom packages
import milano from "../assets/icone/vigevano.png";
import close from "../assets/icone/close.png";
import call from "../assets/icone/call-center.png";

class Info extends React.Component {
  render() {
    return (
      <div id="popup-info">
        <p className="text-right">
          <img
            alt="Chiudi"
            className="close-btn"
            src={close}
            onClick={() => this.props.close()}
          ></img>
        </p>
        <Row id="content-info">
          <Col md={12}>
            <img alt="Vigevano" src={milano} style={{ width: "142px" }}></img>
          </Col>
        </Row>

        <Row className="circle-contact">
          <Col md={12}>
            <img alt="Chiama" src={call} style={{ width: "32px" }}></img>
            <p className="text-regular size-16 no-margin">
              <br />
              Contact Center{" "}
            </p>
            <p className="text-bold size-16">+39 0381 2991</p>
            <p className="text-regular descr">
              LUN-SAB (escluso festivi)
              <br /> dalle 8:00 alle 20:00
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <p className="text-bold no-margin">Comune di Vigevano</p>
            <p className="text-regular descr">
              Corso Vittorio Emanuele II, 25
              <br />
              27029 Vigevano (Pavia)
              <br />
              Italia
              <br />
            </p>
            <a
              className="text-bold"
              href="https://www.comune.vigevano.pv.it"
              style={{ color: "#E20019" }}
            >
              comune.vigevano.pv.it
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Info;
