// Core packages
import React from "react";

// Third party packages
import { Tab, Tabs } from "react-bootstrap";

// Custom packages
import Mappa from "../../pages/Mappa";
import Footer from "../Footer";
import Foto from "./media/Foto";
import Audio from "./media/Audio";
import Video from "./media/Video";
import iconmap from "../../assets/icone/map.png";
import close from "../../assets/icone/close-red.png";
import cat from "../../data/categorie.json";
import poi from "../../data/luoghi.json";

class Content extends React.Component {
  state = {
    more: false,
    id: "",
  };
  closeHandle = () => {
    document.getElementById("popup-info").style.display = "none";
  };
  toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + " ° " + minutes + "' " + seconds + '"';
  }
  componentWillMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    this.setState({ id: id });
  }
  convertDMS(lat, lng) {
    var latitude = this.toDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";
    var longitude = this.toDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";
    return (
      <p className="descr text-regular">
        <strong>{latitudeCardinal}</strong> {latitude} &nbsp;{" "}
        <strong>{longitudeCardinal} </strong>
        {longitude}
      </p>
    );
  }

  render() {
    return poi.map((element) => {
      {
        return element.id == this.state.id ? (
          <div class="ar-content">
            <div className="header-content">
              <img className="close-color" src={close} />
              <img
                className="header-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/luoghi" +
                  element.path +
                  "cover/" +
                  element.img
                }
              />
            </div>
            <div className="ar-content-info">
              {cat.map((el) => {
                if (el.slug == element.categoria) {
                  return (
                    <p className="category-cont" style={{ color: el.color }}>
                      <img
                        className="icon-info"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/categorie/" +
                          el.path +
                          "icona/" +
                          el.img
                        }
                      />
                      <span className="info-mappa-cat">{el.nome}</span>
                    </p>
                  );
                }
              })}
              <h1 className="text-bold">{element.nome}</h1>
              <p className="text-regular descr">
                {this.state.more ? (
                  <span>
                    {element.descrizione.split("\n").map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                    <p
                      className="read-more "
                      onClick={() => this.setState({ more: false })}
                    >
                      Chiudi
                    </p>
                  </span>
                ) : (
                  <span>
                    {element.excerpt.split("\n").map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                    <p
                      className="read-more "
                      onClick={() => this.setState({ more: true })}
                    >
                      Leggi tutto
                    </p>
                  </span>
                )}
              </p>
              <Tabs id="uncontrolled-tab-example">
                {element.gallery == "" || element.gallery == undefined ? (
                  ""
                ) : (
                  <Tab eventKey="foto" title="FOTO">
                    <Foto gall={element.gallery} path={element.path}></Foto>
                  </Tab>
                )}
                {element.video == "" || element.video == undefined ? (
                  ""
                ) : (
                  <Tab eventKey="video" title="VIDEO">
                    <Video video={element.video}></Video>
                  </Tab>
                )}
                {element.audio == "" || element.audio == undefined ? (
                  ""
                ) : (
                  <Tab eventKey="podcast" title="PODCAST">
                    <Audio audio={element.audio} path={element.path}></Audio>
                  </Tab>
                )}
              </Tabs>
            </div>
            <hr></hr>
            <div className="ar-content-info mappa-content">
              <p className="size-18 text-bold">
                MAPPA
                <span className="map-link text-regular">
                  <img src={iconmap} style={{ width: "21px" }} />
                  <a href="/it/mappa">Apri mappa</a>
                </span>
              </p>
              <Mappa current={element.id}></Mappa>
              <br></br>
              <p className="descr text-regular">{element.indirizzo}</p>
              {element.pos != undefined
                ? this.convertDMS(element.pos.lat, element.pos.lng)
                : ""}
            </div>
            <Footer />
          </div>
        ) : (
          ""
        );
      }
    });
  }
}

export default Content;
