// Core packages

// Third party packages

// Custom packages
// import i18nIt from "../../data/i18n.json";
// import i18nEn from "../../data/en/i18n.json";
import luoghiIt from "../../data/luoghi.json";
// import luoghiEn from "../../data/en/luoghi.json";
import categorieIt from "../../data/categorie.json";
// import categorieEn from "../../data/en/categorie.json";

/**
 * Script start
 */
const currentLang = window.location.pathname.startsWith("/en") ? "en" : "it";
console.log("window.location.pathname", window.location.pathname);
console.log("currentLang", currentLang);

const initState = {
  curr_lang: currentLang,
  luoghi: luoghiIt,
  filtri_attivi: categorieIt.map(
    (element) => {
      return { nome: element.slug, stato: true };
    }
  ),
  arStatus: false,
};

console.log("initState", initState);

/**
 * Get requested path (in dot notation) inside given object
 * This is used to translate strings from i18n json file
 */
const translate = (path, data) => {
  const string = path.split(".").reduce((r, k) => r?.[k], data);
  if (!string) {
    return path;
  }
  return string;
};

// initState.t = (path) => translate(path, i18nIt);

const reducers = (state = initState, action) => {
  console.log("action: ", action);
  switch (action.type) {
    // case "CHANGE_IT":
    //   if (window.location.pathname.split("/en/")[1]) {
    //     window.history.pushState(
    //       "page2",
    //       "ita",
    //       "/it/" + window.location.pathname.split("/en/")[1]
    //     );
    //   } else {
    //     window.history.pushState("page2", "ita", "/it/");
    //   }
    //   return Object.assign({}, state, {
    //     curr_lang: "it",
    //     luoghi: luoghiIt,
    //     t: (path) => translate(path, i18nIt),
    //   });

    // case "CHANGE_EN":
    //   if (window.location.pathname.split("/it/")[1]) {
    //     window.history.pushState(
    //       "page2",
    //       "eng",
    //       "/en/" + window.location.pathname.split("/it/")[1]
    //     );
    //   } else {
    //     window.history.pushState("page2", "eng", "/en/");
    //   }
    //   return Object.assign({}, state, {
    //     curr_lang: "en",
    //     t: (path) => translate(path, i18nEn),
    //   });

    case "ADD_FILTER":
      let temp = state.filtri_attivi.slice(0);
      state.filtri_attivi.forEach((element, index) => {
        if (element.nome === action.filter) {
          if (element.stato) {
            temp[index].stato = false;
          } else {
            temp[index].stato = true;
          }
        }
      });

      return Object.assign({}, state, {
        filtri_attivi: temp,
      });

    case "SET_AR_STATUS":
      const newState = Object.assign({}, state, {
        arStatus: action.arStatus,
      });
      return newState;

    default:
      return state;
  }
};

export default reducers;
