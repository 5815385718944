// Core packages
import React from "react";

// Third party packages
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { Row, Col } from "react-bootstrap";

// Custom packages
import ar from "../assets/icone/ar-black.png";
import inquadra from "../assets/icone/inquadra.png";
import back from "../assets/icone/back-arrow.png";
import map from "../assets/icone/black-map.png";
import scopri from "../assets/icone/scopri.png";
import info1 from "../assets/img/info-1.png";
import info3 from "../assets/img/info-3.png";
import info4 from "../assets/img/info-4.png";
import info2 from "../assets/img/info-2.png";
import redmap from "../assets/icone/map.png";
import redar from "../assets/icone/ar-red.png";

/**
 * Script start
 */
class SliderDot extends React.Component {
  backHome = () => {
    window.location.href = "/homepage/";
  };

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
      appendDots: (dots) => {
        return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
      },
    };

    return (
      <div>
        <p className="text-left">
          <img
            src={back}
            alt="Indietro"
            style={{ width: "36px", cursor: "pointer", margin: "15px" }}
            onClick={() => this.backHome()}
          ></img>
        </p>

        <Slider {...settings}>
          <Col md={12} className="slider-el">
            <img
              src={ar}
              style={{ maxWidth: "46px" }}
              className="content-center"
            ></img>
            <p className="text-bold title">
              CERCA I PUNTI DI INTERESSE INTORNO A TE
            </p>
            <img src={info1} className="content-center info-img"></img>
            <p className="descr text-regular">
              Entra nella straordinaria esperienza della{" "}
              <strong>Realtà Aumentata </strong>
              <img src={redar} className="info-img-text"></img> e muoviti con il
              tuo smartphone cercando Punti di Interesse (POI) intorno alla
              posizione in cui ti trovi. Puoi filtrare le Categorie per
              visualizzare solo quelle di tuo interesse.
            </p>
          </Col>
          <Col md={12} className="slider-el">
            <img
              src={map}
              style={{ maxWidth: "39px" }}
              className="content-center"
            ></img>
            <p className="text-bold title">
              ORIENTATI CON LA MAPPA INTERATTIVA
            </p>
            <img src={info2} className="content-center info-img"></img>
            <p className="descr text-regular">
              Entrando nella funzionalità <strong>Mappa</strong>{" "}
              <img src={redmap} className="info-img-text"></img>
              potrai orientarti facilmente nello spazio scoprendo quali Punti di
              Interesse (POI) sono intorno a te. Anche qui puoi filtrare le
              Categorie da visualizzare.
            </p>
          </Col>
          <Col md={12} className="slider-el">
            <img
              src={inquadra}
              style={{ maxWidth: "34px" }}
              className="content-center"
            ></img>
            <p className="text-bold title">INQUADRA IL PUNTO DI INTERESSE</p>
            <img src={info3} className="content-center info-img"></img>
            <p className="descr text-regular">
              Spostati con il tuo smartphone facendo in modo che il riquadro al
              centro dello schermo si posizioni sopra Punto di Interesse (POI)
              di cui vuoi avere informazioni.
            </p>
          </Col>
          <Col md={12} className="slider-el">
            <img
              src={scopri}
              style={{ maxWidth: "46px" }}
              className="content-center"
            ></img>
            <p className="text-bold title">
              SCOPRI TUTTE LE INFORMAZIONI DEL LUOGO
            </p>
            <img src={info4} className="content-center info-img"></img>
            <p className="descr text-regular">
              In un attimo potrai scoprire tutte le informazioni, la storia le
              curiosità del luogo storico o dell’attività commerciale che hai
              selezionato. Facile vero? Prova subito!
            </p>
          </Col>
        </Slider>
      </div>
    );
  }
}

export default SliderDot;
